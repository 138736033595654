<template>
  <v-app id="main-app">
    <v-container class="fill-height" fluid>
      <v-card class="mx-auto px-10 pb-9 reset-password-card" width="450px">
        <v-card-title v-if="!apiLoaded" class="justify-center pt-12">
          <span
            style="font-family: Roboto, serif !important; font-size: 18px;"
            class="main_red--text"
          >
            {{ message }}
          </span>
        </v-card-title>
        <v-card-title v-if="apiLoaded" class="justify-center pt-12">
          <img
            v-bind:src="logo.src"
            v-bind:style="{ width: logo.width, height: logo.height }"
          />
        </v-card-title>
        <v-card-subtitle v-if="apiLoaded" class="text-center py-6 title-login">
          {{ $t('setNewPassword') }}
        </v-card-subtitle>
        <v-card-text v-if="apiLoaded" class="text-center">
          <v-form ref="form1" @submit.prevent="login">
            <v-text-field
              v-model="password"
              label="Password"
              placeholder=" "
              name="password"
              background-color="#E8E8E8"
              rounded
              outlined
              style="
                border-radius: 12px !important;
                font-family: 'Roboto', serif !important;
              "
              spellcheck="false"
              :autocomplete="showPassword ? 'off' : 'current-password'"
              :rules="[passwordRequired]"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
            />
            <v-text-field
              v-model="confirmPassword"
              label="Re-type password"
              placeholder=" "
              name="password"
              background-color="#E8E8E8"
              rounded
              outlined
              style="
                border-radius: 12px !important;
                font-family: 'Roboto', serif !important;
              "
              spellcheck="false"
              :autocomplete="showNewPassword ? 'off' : 'current-password'"
              :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[confirmPasswordRequired]"
              :type="showNewPassword ? 'text' : 'password'"
              @click:append="showNewPassword = !showNewPassword"
              @keyup.enter="clickButton"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn text class="ma-2 title-reset-password" @click="goToSignIn">
            <span>{{ $t('goToSignInPage') }}</span>
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="apiLoaded"
            color="primary"
            large
            class="ma-3 button-login"
            :loading="loading"
            :disabled="loading || completed"
            @click="loader = 'loading'"
          >
            <span>{{ $t('submit') }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-footer color="transparent" absolute padless>
        <v-col class="text-center black--text" cols="12">
          <!-- &#183; <img :src="logo_bridge" height="24px" /> &#183; -->
        </v-col>
      </v-footer>
    </v-container>
  </v-app>
</template>
<script>
import _axios from '@/plugins/axios';
import logoBridge from '../../../public/logo_bridge.png';
const { LOGIN_LOGO } = require('@/themes/VitalCheckups/image-paths');
export default {
	components: {},
	data () {
		return {
			loader: null,
			loading: false,
			logo: LOGIN_LOGO,
			apiLoaded: false,
			message: null,
			showForm: 1,
			showPassword: false,
			showInputs: false,
			logo_bridge: logoBridge,
			password: '',
			confirmPassword: '',
			showNewPassword: false,
			snackStatus: false,
			snackTitle: '',
			snackColor: '',
		};
	},
	computed: {
		passwordRequired () {
			if (this.password.length < 8) { return 'Password must have more than eight characters'; }
			if (
				this.password !== this.confirmPassword &&
        this.confirmPassword.length > 7
			) { return 'Fields do not match'; }
			return !!this.password;
		},
		confirmPasswordRequired () {
			if (this.confirmPassword.length < 8) { return 'Password must have more than eight characters'; }
			if (this.password !== this.confirmPassword) return 'Fields do not match';
			return !!this.confirmPassword;
		},
		completed () {
			if (
				this.passwordRequired === true &&
        this.confirmPasswordRequired === true
			) { return false; }
			return true;
		},
	},
	watch: {
		async loader () {
			if (this.loader !== null) {
				const l = this.loader;
				this[l] = !this[l];
				await this.clickButton()
					.then(() => {
						this[l] = false;
					})
					.catch(() => {
						this[l] = false;
					});
				this.loader = null;
			}
		},
	},
	async mounted () {
		const response = await _axios.get(this.$route.fullPath.split('api')[1]);
    this.isPatient = this.$route.query.isPatient;
    this.isCaregiver = this.$route.query.isCaregiver;
		if (response.status === 200) {
			if (!response.data.resFlag) {
				this.message = response.data.msg;
				this.apiLoaded = false;
			} else this.apiLoaded = true;
		}
	},
	methods: {
		async changePassword () {
			this.snackStatus = false;
			const token = this.$route.query.validationtoken;
			const body = {
				NewPassword: this.confirmPassword,
				ValidationToken: token,
			};
			await this.$store
				.dispatch('users/changePasswordWithValidationToken', body)
				.then(() => {
					this.snackStatus = true;
					this.snackColor = 'teal darken-2';
					this.title = this.$t('passwordChangedSuccessfully');
          if (this.isPatient === 'true' || this.isPatient === true || this.isCaregiver === 'true' || this.isCaregiver === true) {
            // this.$router.push({ name: 'SuccessfullyChangedPassword' });
            this.$router.push({ name: 'SuccessfullyChangedPassword', query: { language: this.$route.query.language } });
          } else {
            this.$router.push({ name: 'Login' });
          }
				})
				.catch(() => {
					this.snackStatus = true;
					this.snackColor = 'deep-orange darken-4';
					this.title = this.$t('passwordCouldNotBeChanged');
				});
		},
		clickButton () {
			this.changePassword();
		},
		goToSignIn () {
			this.$router.replace({ name: 'Login' });
		},
	},
};
</script>
<style scoped>
#main-app {
  background: linear-gradient(#e8e8e8, #d6d6d6);
}
</style>
